import React from 'react';
import '../styles/Home.css';
import { useNavigate } from 'react-router-dom';


function Home() {

    const navigate = useNavigate();

    const handleIngresarClick = () => {
        navigate('/ingresoPersona');
    };

    const handleConsultarClick = () => {
        navigate('/consultaPersona');
    };

    const token = localStorage.getItem('token');

    const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
};

    return (
        <div className='home'>
            <navbar>
                <ul>
                    <li><a href='/home'>Home</a></li>
                    <li><a href='/ingresoPersona'>Ingreso de Personas</a></li>
                    <li><a href='/consultaPersona'>Consulta de Personas</a></li>
                    <li><a href='/' onClick={handleLogout}>Cerrar Sesión</a></li>
                </ul>
            </navbar>
            <div className='home'>
                <div className='card'>
                    <title>Ingreso de Personas</title>
                    <h1>Ingreso de Personas</h1>
                    <p>Presione aquí para tomar los datos</p>
                    <button type='submit' onClick={handleIngresarClick}>Ingresar</button>
                </div>
                <div className='card'>
                    <title>Consulta de Visitas</title>
                    <h1>Consulta de Visitas</h1>
                    <p>Presione aquí para consultar los datos</p>
                    <button type='submit' onClick={handleConsultarClick}>Consultar</button>
                </div>
            </div>
        </div>
    );
}

function tablaPersonas () {
    return (
        <table>
            <thead>
                <tr>
                    <th>Rut</th>
                    <th>Nombre</th>
                    <th>Apellido</th>
                    <th>Fecha de nacimiento</th>
                    <th>Sexo</th>
                    <th>Estado Civil</th>
                    <th>Nacionalidad</th>
                    <th>Región</th>
                    <th>Comuna</th>
                    <th>Dirección</th>
                    <th>Teléfono</th>
                    <th>Email</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>12.345.678-9</td>
                    <td>Nombre</td>
                    <td>Apellido</td>
                    <td>01/01/2000</td>
                    <td>Masculino</td>
                    <td>Soltero</td>
                    <td>Chilena</td>
                    <td>Región</td>
                    <td>Comuna</td>
                    <td>Dirección</td>
                    <td>123456789</td>
                </tr>
            </tbody>
        </table>
    )
}

export default Home;
export { tablaPersonas };