import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RutasPrivadas = ({ element: Component, ...rest }) => {
  const token = localStorage.getItem('token');
  const location = useLocation();

  return token ? 
    <Component {...rest} /> : 
    <Navigate to="/" state={{ from: location }} replace />;
};

export default RutasPrivadas;
