import React from "react";
import "../styles/crearUsuario.css";

function CrearUsuario() {
    return (
        <div className="formularioCreacion">
            <h1>Crear Usuario</h1>
            <form>
                <label htmlFor="rut">Rut</label>
                <input type="text" id="rut"/>
                <label htmlFor="nombre">Nombre</label>
                <input type="text" id="nombre"/>
                <label htmlFor="apellido">Apellido</label>
                <input type="text" id="apellido"/>
                <label htmlFor="email">Email</label>
                <input type="text" id="email"/>
                <label htmlFor="region">Region</label>
                <select type="text" id="region">
                    <option value="15">Arica y Parinacota</option>
                    <option value="1">Tarapacá</option>
                    <option value="2">Antofagasta</option>
                    <option value="3">Atacama</option>
                    <option value="4">Coquimbo</option>
                    <option value="5">Valparaíso</option>
                    <option value="13">Metropolitana</option>
                    <option value="6">Libertador B. O'Higgins</option>
                    <option value="7">Maule</option>
                    <option value="8">Bíobío</option>
                    <option value="16">Ñuble</option>
                    <option value="9">Araucanía</option>
                    <option value="14">Los Ríos</option>
                    <option value="10">Los Lagos</option>
                    <option value="11">Aisén</option>
                    <option value="12">Magallanes</option>
                </select>
                <label htmlFor="password">Password</label>
                <input type="text" id="password"/>
                <button type="submit">Crear Usuario</button>
            </form>
        </div>
    );
}

export default CrearUsuario;