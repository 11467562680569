import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Login from '../../pages/Login';
import Home from '../../pages/Home';
import IngresoPersona from '../../pages/ingresoPersona';
import ConsultaPersona from '../../pages/consultaPersona';
import CrearUsuario from '../../pages/crearUsuario';
import RutasPrivadas from './rutasPrivadas';

function Router() {
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<RutasPrivadas element={Home}/>} />
        <Route path="/ingresoPersona" element={<RutasPrivadas element={IngresoPersona}/>} />
        <Route path="/consultaPersona" element={<RutasPrivadas element={ConsultaPersona}/>} />
        <Route path="/crearUsuario" element={<RutasPrivadas element={CrearUsuario}/>} />
      </Routes>
    );
  }
  
  export default Router;