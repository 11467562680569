import '../styles/Login.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../includes/serca logo.png';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
  try {
    const response = await fetch('http://localhost:4001/api/authentication', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });

    const responseData = await response.json();
    console.log(responseData);

    if (response.ok) {
      const { token } = responseData;
      localStorage.setItem('token', token);
      navigate('/home');
      console.log('navegando a home');
    } else {
      console.error('Error en la autenticación 1:', response.statusText);
      alert("Error en la autenticación");
    }
  } catch (error) {
    console.error('Error en la autenticación 2:', error.message);
    alert("Error en la autenticación");
  }
};

  return (
    <div className="login">
      <img src={logo} alt="logo"/>
      <form id="login-form" onSubmit={handleLogin}>
        <label htmlFor="username">Usuario:</label>
        <input
          type="text"
          id="username"
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        /><br />
        <label htmlFor="password">Contraseña:</label>
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        /><br />
        <button onClick={handleLogin}>Ingresar</button>
      </form>
    </div>
  );
}

export default Login;
