import React from 'react';

function ConsultaPersona() {


    return (
        <div>
            <h1>Consulta de personas</h1>
            <p>Ingrese Rut de persona a buscar</p>
            <form>
                <label htmlFor="rut">Rut</label>
                <input type="text" id="rut" name="rut" required /><br />
                <button type="submit">Buscar</button>
            </form>
        </div>
    );
}
export default ConsultaPersona;