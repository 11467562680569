// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div .formularioingreso {
    width: 40%;
    height: 100%;
    display: flex;
    margin-left: 30%;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 20px;
    padding: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
}

    div .formularioingreso h2 {
        font-size: 30px;
        font-weight: 600;
        color: #000;
    }

select {
    width: 25% ;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/styles/ingresoPersona.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,6CAA6C;AACjD;;IAEI;QACI,eAAe;QACf,gBAAgB;QAChB,WAAW;IACf;;AAEJ;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;IACZ,eAAe;AACnB","sourcesContent":["div .formularioingreso {\r\n    width: 40%;\r\n    height: 100%;\r\n    display: flex;\r\n    margin-left: 30%;\r\n    margin-top: 10px;\r\n    margin-bottom: 10px;\r\n    align-items: center;\r\n    flex-direction: column;\r\n    justify-content: space-around;\r\n    align-items: center;\r\n    background-color: #f2f2f2;\r\n    border-radius: 20px;\r\n    padding: 10px;\r\n    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);\r\n}\r\n\r\n    div .formularioingreso h2 {\r\n        font-size: 30px;\r\n        font-weight: 600;\r\n        color: #000;\r\n    }\r\n\r\nselect {\r\n    width: 25% ;\r\n    height: 30px;\r\n    border-radius: 5px;\r\n    border: 1px solid #ccc;\r\n    padding: 5px;\r\n    font-size: 18px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
