import "../styles/ingresoPersona.css";
import React from "react";
import { useNavigate } from "react-router-dom";

function IngresoPersona() {

    const navigate = useNavigate();

    const rut = "";
    const fechaNac = "";
    const sexo = "";
    const pNombre = "";
    const sNombre = "";
    const pApellido = "";
    const sApellido = "";
    const direccion = "";

    const scanCambio = (event) => {
        event.preventDefault();
        const scan = document.getElementById("scan").value;
        document.getElementById("pNombre").value = (scan.slice(60,90)).split(";")[3];
        document.getElementById("sNombre").value = (scan.slice(60,90)).split(";")[4];
        document.getElementById("pApellido").value = (scan.slice(60,90)).split(";")[0];
        document.getElementById("sApellido").value = (scan.slice(60,90)).split(";")[1];
        document.getElementById("rut").value = (scan[48]+scan[49]+'.'+scan[50]+scan[51]+scan[52]+'.'+scan[53]+scan[54]+scan[55]+'-'+scan[57]);
        document.getElementById("fechaNac").value = (scan[34]+scan[35]+'-'+scan[32]+scan[33]+'-'+scan[30]+scan[31]);
    }

    document.addEventListener("keydown", function(event) {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Datos guardados");
        navigate('/Home');
    };

    return (
        <div className="formularioingreso">
            <h1>Ingreso de Persona</h1>
            <h2>Escanea el código de la Cedula</h2>
            <form className="formularioDatos" onSubmit={handleSubmit}>
            <label htmlFor="scan">Scan</label>
            <input type="text" id="scan" onChange={scanCambio}/>
            <label htmlFor="pNombre">Primer Nombre</label>
            <input type="text" id="pNombre"/>
            <label htmlFor="sNombre">Segundo Nombre</label>
            <input type="text" id="sNombre"/>
            <label htmlFor="pApellido">Primer Apellido</label>
            <input type="text" id="pApellido"/>
            <label htmlFor="sApellido">Segundo Apellido</label>
            <input type="text" id="sApellido"/>
            <label htmlFor="rut">Rut</label>
            <input type="text" id="rut"/>
            <label htmlFor="fechaNac">Fecha de Nacimiento</label>
            <input type="text" id="fechaNac"/>
            <label htmlFor="sexo">Sexo</label><br/>
            <select id="sexo">
                <option value="M">Masculino</option>
                <option value="F">Femenino</option>
            </select><br/>
            <label htmlFor="direccion">Dirección</label>
            <input type="text" id="direccion"/>
            <button type="submit" onClick={handleSubmit}>Guardar</button>
            </form>
        </div>
    );
}

export default IngresoPersona;